<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div
            class="boq-container"
            ref="document"
            v-if="Object.keys(EHDDetails).length > 0"
        >
            <div class="boq-header">
                <div class="company-logo">
                    <img src="@/assets/images/Group 479.png" alt="SILOHO" />
                </div>
                <div class="company-details">
                    <div class="company-email">
                        <img src="@/assets/images/email.png" />
                        <p>info@siloho.com</p>
                    </div>
                    <div class="company-url">
                        <img src="@/assets/images/earth.png" />
                        <p>www.siloho.com</p>
                    </div>
                    <div class="company-number">
                        <img src="@/assets/images/telephone.png" />
                        <p>+91 8-805-806-805</p>
                    </div>
                </div>
            </div>
            <div class="boq-body">
                <div class="customer-details">
                    <div class="customer-name">
                        <p>CLIENT NAME :</p>
                        <p>{{ userData.firstname }} {{ userData.lastname }}</p>
                    </div>
                    <div class="customer-address">
                        <p>SITE ADRESS :</p>
                        <p>{{ EHDDetails.unit_address }}</p>
                    </div>
                    <div class="please-note">
                        PLEASE NOTE: IT IS AN ESTIMATED COST AND THE ACTUALS
                        MIGHT VARY ± 10%
                    </div>
                    <div class="download-button">
                        <Button
                            name="Download PDF"
                            primary
                            @click.native="downloadPDF"
                        />
                    </div>
                </div>
                <div
                    v-for="(scene, index) in EHDDetails.scene_data"
                    :key="index"
                    class="boq-scene"
                >
                    <div
                        class="scene-name"
                        v-if="
                            scene.base_products.length > 0 ||
                                scene.base_services.length > 0
                        "
                    >
                        <p>{{ scene.room_type }}</p>
                    </div>

                    <template v-if="scene.base_products.length > 0">
                        <div
                            v-for="product in scene.base_products"
                            :key="product.id"
                            class="products-from-scene"
                        >
                            <div class="product-img">
                                <img
                                    :src="
                                        product.image_url
                                            ? product.image_url
                                            : require('@/assets/images/default-product-img.png')
                                    "
                                />
                            </div>
                            <p class="product-name">
                                {{ product.name.slice(0, 90)
                                }}<span v-if="product.name.length > 90"
                                    >...</span
                                >
                            </p>
                            <div
                                v-html="product.description.slice(0, 110)"
                                class="product-description"
                            ></div>
                            <p class="product-quantity">
                                {{ product.active_quantity }}
                            </p>
                            <p
                                class="product-cost"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹{{
                                    convertToIndianNumberSystem(
                                        product.item_price,
                                    )
                                }}
                            </p>
                        </div>
                        <div class="total-products">
                            <p>
                                Total Products
                                <span>({{ scene.base_products.length }})</span>
                            </p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.price_of_products,
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-if="scene.base_services.length > 0">
                        <div
                            v-for="services in scene.base_services"
                            :key="services.id"
                        >
                            <div
                                v-for="service in services.data"
                                :key="service.id"
                                class="services-from-scene"
                            >
                                <img
                                    v-if="service.active == true"
                                    :src="
                                        service.service_icon_url
                                            ? service.service_icon_url
                                            : require('@/assets/images/enable_service.png')
                                    "
                                    class="service-img"
                                />
                                <p class="service-name">{{ service.name }}</p>
                                <p class="service-vendor">
                                    Vendor: {{ service.vendor }}
                                </p>
                                <p
                                    class="service-cost"
                                    :class="
                                        requiredDomain == false ? 'blur' : ''
                                    "
                                >
                                    ₹{{
                                        convertToIndianNumberSystem(
                                            service.price,
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="total-services">
                            <p>
                                Total Services
                                <span>({{ scene.active_services }})</span>
                            </p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.price_of_services,
                                    )
                                }}
                            </p>
                        </div>
                    </template>

                    <div
                        class="scene-summary"
                        v-if="
                            scene.base_products.length > 0 ||
                                scene.base_services.length > 0
                        "
                    >
                        <div class="scene-prod">
                            <p class="style-bold">
                                {{ scene.room_type }} Products
                                <span>({{ scene.base_products.length }})</span>
                            </p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.price_of_products,
                                    )
                                }}
                            </p>
                        </div>
                        <div class="scene-serv">
                            <p class="style-bold">
                                {{ scene.room_type }} Services
                                <span>({{ scene.active_services }})</span>
                            </p>
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.price_of_services,
                                    )
                                }}
                            </p>
                        </div>
                        <div class="total-for-scene">
                            <p class="style-bold">Subtotal</p>
                            <p
                                class="style-bold"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹{{ convertToIndianNumberSystem(scene.price) }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="grand-total-breakdown">
                    <div class="breakdown-header">
                        <p>Grand Total</p>
                    </div>
                    <div
                        v-for="(scene, index) in scenePrices"
                        :key="index"
                        class="scene-breakdown"
                    >
                        <div v-if="index == 0" class="breakdown-headers">
                            <p>Products</p>
                            <p>Services</p>
                            <p>Total</p>
                        </div>
                        <div class="breakdown-data">
                            <p class="scene-name">{{ scene.scene_name }}</p>
                            <p
                                class="product-details"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.prod_total,
                                    )
                                }}
                            </p>
                            <p
                                class="scene-details"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹{{
                                    convertToIndianNumberSystem(
                                        scene.serv_total,
                                    )
                                }}
                            </p>
                            <p
                                class="scene-total"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹{{ convertToIndianNumberSystem(scene.total) }}
                            </p>
                        </div>
                    </div>
                    <div class="grand-total">
                        <p>Grand Total</p>
                        <p :class="requiredDomain == false ? 'blur' : ''">
                            ₹{{
                                convertToIndianNumberSystem(
                                    EHDDetails.total_cost,
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import EHDPageModule from 'store/modules/EHDPage'
import Button from 'componentsv2/Button'
import FAPOPageModule from 'store/modules/FAPOPage'
import html2pdf from 'html2pdf.js/dist/html2pdf.bundle'
import loaderHandler from 'mixins/loader'
import Loader from 'componentsv2/Loader'
export default {
    name: 'DownloadBOQ',
    components: {
        Button,
        Loader,
    },
    props: {
        requiredDomain: {
            type: Boolean,
        },
    },
    computed: {
        ...mapState({
            EHDDetails: state => state.EHDPage.EHDDetails,
            priceDetails: state => state.FAPOPage.priceDetails,
            userData: state => state.AuthModule.userData,
        }),
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    data() {
        return {
            scenePrices: [],
        }
    },
    created() {
        this.registerStoreModule('EHDPage', EHDPageModule)
        this.registerStoreModule('FAPOPage', FAPOPageModule)
        this.fetchEHDDetailsv3(this.$route.query.ehd_id)
            .then(response => {
                if (response.responseCode == 200) {
                    response.data.scene_data.forEach(scene => {
                        this.scenePrices.push({
                            scene_name: scene.room_type,
                            prod_total: scene.price_of_products,
                            serv_total: scene.price_of_services,
                            total: scene.price,
                        })
                    })
                }
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
    },
    methods: {
        ...mapActions({
            fetchEHDDetailsv3: 'fetchEHDDetailsv3',
            fetchPriceDetails: 'fetchPriceDetails',
        }),
        downloadPDF() {
            document.querySelector('.download-button').style.display = 'none'
            html2pdf(this.$refs.document, {
                margin: 3,
                filename:
                    this.userData.firstname +
                    ' ' +
                    this.userData.lastname +
                    '.pdf',
                html2canvas: { dpi: 150, letterRendering: true, useCORS: true },
            })
            setTimeout(() => {
                document.querySelector('.download-button').style.display =
                    'block'
            }, 1000)
        },
    },
}
</script>

<style lang="scss" scoped>
@import './DownloadBOQ.scss';
</style>
